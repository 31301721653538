import React, {useState} from 'react';
import {CardknoxApplePay}  from '@cardknox/react-ifields';

export default function AppForm() {
  const [amount, setAmount] = useState(1.25);
  const [shippingMethod, setShippingMethod] = useState(null);
  const [shippingContact, setShippingContact] = useState(null);
  const [paymentType, setPaymentType] = useState('credit');
  const [apPayload, setPayload] = useState('');

  const toNumber = (value) => {
    const number = Number(value);
    if (isNaN(number)) return 0;
    return number;
  };

  const getApplePayProperties = () => {
    return {
        merchantIdentifier: 'merchant.aptest.cardknoxdev.com',
        requiredShippingContactFields: ['postalAddress']
    }
  };

  const getApplePayTransInfo = (newShippingContact, newShippingMethod, newPaymentType) => {
    const getShippingContact = () => newShippingContact || shippingContact;
    const getShippingMethod = () => newShippingMethod || shippingMethod || {
      label: 'Free Shipping',
      amount: '0.00',
      identifier: 'free',
      detail: 'Delivers in five business days',
    };
    const getPaymentType = () => newPaymentType || paymentType;

    const getTotal = () => {
      let total = 0;
      lineItems.forEach((item) => {          
        total += toNumber(item.amount);
      });
      return total.toFixed(2);
    };
    console.log('getApplePayTransInfo', amount, shippingMethod, shippingContact, paymentType);
    const lineItems = [
        {
            label: "Subtotal",
            type: "final",
            amount: amount.toFixed(2)
        },
        getShippingMethod()
    ]; 
    if (getPaymentType() === "credit") {
      lineItems.push({
          label: "Credit Card Fee",
          amount: (0.0275*amount).toFixed(2),
          type: "final"
      });
    }   
    let taxAmount = 0.01;
    const currentShippingContact = getShippingContact();
    const hasShipping = currentShippingContact && currentShippingContact.administrativeArea;
    if (hasShipping) {
      if (currentShippingContact.administrativeArea === "NY") {
        taxAmount = 0.0875;
      } else if (currentShippingContact.administrativeArea === "NJ") {
        taxAmount = 0.07;
      }
    } 
    if (taxAmount > 0.00) {
      lineItems.push({
          label: "Estimated Tax",
          amount: (taxAmount*amount).toFixed(2),
          type: "final"
      });
    }
    const total = {
        type:  "final",
        label: "Total",
        amount: getTotal()
    };
    return {
        lineItems,
        total
    };
  };

  const getApplePayShippingMethods = () => {
    return [
        {
            label: 'Free Shipping',
            amount: '0.00',
            identifier: 'free',
            detail: 'Delivers in five business days',
        },
        {
            label: 'Express Shipping',
            amount: '1.50',
            identifier: 'express',
            detail: 'Delivers in two business days',
        },
    ];
  };

  const getApplePayShippingContact = shippingContact => {
    return new Promise((resolve, reject) => {
        try {
            console.log('applePayShippingContact', shippingContact);
            setShippingContact(shippingContact);
            const result = getApplePayTransInfo(shippingContact);
            resolve(result);
        } catch(error) {
            console.error("onShippingContactSelected error.", error);
            reject(error);
        }
    });
  };

  const getApplePayShippingMethod = shippingMethod => {
    return new Promise((resolve, reject) => {
        try {
            console.log('applePayShippingMethod', shippingMethod);
            setShippingMethod(shippingMethod);
            const result = getApplePayTransInfo(null, shippingMethod);
            resolve(result);
        } catch(error) {
            console.error("onShippingMethodSelected error.", error);
            reject(error);
        }
    });
  };

  const getApplePayPaymentMethod = paymentMethod => {
    return new Promise((resolve, reject) => {
        try {
            console.log('applePayPaymentMethod', paymentMethod);
            setPaymentType(paymentMethod.type);
            const result = getApplePayTransInfo(null, null, paymentMethod.type);
            resolve(result);                            
        } catch(error) {
            console.error("onPaymentMethodSelected error.", error);
            reject(error);
        }
    });
  };

  const applePayPaymentAuthorize = paymentResponse => {
    return new Promise((resolve, reject) => {
        try {
            console.log('applePayPaymentAuthorize', paymentResponse);
            setPayload(JSON.stringify(paymentResponse, null, 2));
            resolve(paymentResponse);
        } catch(error) {
            console.error("onPaymentAuthorize error.", error);
            reject(error);
        }
    });
  };

  return (
    <div>
      <div className='main'>
        <div className='hero-body'>
          <div className='container'>
            <h1 className='title'>
              Checkout
            </h1>
          </div>
        </div>
        <section className='box card-box'>
          <div className="field">
            <label className="label">
              Amount
              <div className="control">
                <input className="input" type="text" placeholder="Enter amount" value={amount} onChange={(e) => setAmount(e.target.value)} />
              </div>
            </label>
          </div>
          <div style={{width:'175px',display:'block',marginTop:'12px'}}>
            <CardknoxApplePay enableLogging={true} 
              properties={getApplePayProperties()} 
              onGetTransactionInfo={getApplePayTransInfo} 
              onGetShippingMethods={getApplePayShippingMethods}
              onShippingContactSelected={getApplePayShippingContact}
              onShippingMethodSelected={getApplePayShippingMethod}
              onPaymentMethodSelected={getApplePayPaymentMethod}
              onPaymentAuthorize={applePayPaymentAuthorize} 
            />
          </div>   
          <hr />             
          <div style={{width:'225px',display:'block',marginTop:'12px'}}>
            <CardknoxApplePay enableLogging={true} 
              properties={getApplePayProperties()} 
              onGetTransactionInfo={getApplePayTransInfo} 
              onGetShippingMethods={getApplePayShippingMethods}
              onShippingContactSelected={getApplePayShippingContact}
              onShippingMethodSelected={getApplePayShippingMethod}
              onPaymentMethodSelected={getApplePayPaymentMethod}
              onPaymentAuthorize={applePayPaymentAuthorize} 
            />
          </div>   
        </section>
        <section className='box result-box'>
          <div className='field'>
            <p className='label'>Apple Pay Payload</p>
            <textarea className="input scrollable" readOnly="1" cols={43} rows={10} value={apPayload} />
          </div>
        </section>
      </div>
    </div>
  );
}